<template>
  <div class="activity-controllers">
    <MonthPicker :value="month" @input="$emit('date-changed', $event)" />
    <el-dropdown trigger="click" @command="$emit('more-actions', $event)">
      <Button type="secondary" class="more-actions">
        <KebabIcon />
      </Button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="MORE_COMMANDS.SHOW_ALL_ISSUES">{{
          $t('eventMap.moreActions.showOpenIssues')
        }}</el-dropdown-item>
        <el-dropdown-item :command="MORE_COMMANDS.MATCH_CREDITS">{{
          $t('eventMap.moreActions.matchCredits')
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { Button, MonthPicker } from '@/modules/core';
import { KebabIcon } from '@/assets/icons';

export const MORE_COMMANDS = {
  SHOW_ALL_ISSUES: 'showOpenIssues',
  MATCH_CREDITS: 'matchCredits',
};

export default {
  components: { MonthPicker, Button, KebabIcon },
  props: {
    month: { type: String, required: true },
  },
  data() {
    return {
      MORE_COMMANDS,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.activity-controllers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 104%;

  > * {
    padding: 0 5px;
  }

  .more-actions {
    padding: 6px;
  }
}

.el-dropdown-link {
  font-weight: 500;
  font-size: 17px;
  line-height: 104%;
  color: $primary;
  cursor: pointer;
}
</style>

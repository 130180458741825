<template>
  <el-tooltip
    :disabled="!showAsterisk"
    effect="dark"
    :content="$t('creditMatchModal.table.common.partialAmount')"
    placement="top"
  >
    <span dir="ltr">
      {{ formatToCurrency(value) ?? '-' }}
      <span v-if="showAsterisk" class="incomplete-total">*</span>
    </span>
  </el-tooltip>
</template>

<script>
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  props: {
    value: { type: Number, required: true },
    showAsterisk: { type: Boolean, default: false },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    return { formatToCurrency };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.incomplete-total {
  color: $warning;
}
</style>

<template>
  <el-dialog
    visible
    top="2%"
    :show-close="false"
    append-to-body
    custom-class="credit-match-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="header d-flex justify-content-between">
        <div>
          <h2 class="title">{{ $t('creditMatchModal.title') }}</h2>
          <p>{{ supplier.name }}</p>
        </div>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="modal-content-wrapper">
      <div class="tables">
        <div class="table-wrapper">
          <div class="table-header">
            <PeriodNavigator
              :start="ordersRange.start"
              :end="ordersRange.end"
              @forward="goForwardOrders()"
              @backward="goBackwardOrders()"
            />
            <h3 class="table-title fw-bold">
              {{ $t('creditMatchModal.table.differences.title') }}
            </h3>
          </div>
          <OrderDifferencesTable
            v-loading="orderLoading"
            :orders-differences="ordersDifferences"
            :selected-index="ordersDifferences.indexOf(differenceItem)"
            @row-click="differenceItem = $event"
            @document-click="previewDocumentId = $event"
            @unlink-matched="handleUnlink"
          />
        </div>
        <div class="divider" />
        <div class="table-wrapper">
          <div class="table-header">
            <PeriodNavigator
              :start="billingsRange.start"
              :end="billingsRange.end"
              @forward="goForwardBillings()"
              @backward="goBackwardBillings()"
            />
            <h3 class="table-title fw-bold">
              {{ $t('creditMatchModal.table.credits.title') }}
            </h3>
          </div>
          <CreditsTable
            v-loading="billingsLoading"
            :credits="credits"
            :selected-index="credits.indexOf(creditItem)"
            @row-click="creditItem = $event"
            @document-click="previewDocumentId = $event"
            @unlink-matched="handleUnlink"
          />
        </div>
      </div>
      <Match :total-difference="differenceItem.balance" :total-credit="creditItem.balance" @match="handleLink" />
    </div>
    <div slot="footer">
      <Button @click="$emit('close')">{{ $t('creditMatchModal.finish') }}</Button>
    </div>
    <DocumentModal
      v-if="previewDocumentId"
      visible
      :document-id="previewDocumentId"
      @close="previewDocumentId = null"
    />
  </el-dialog>
</template>

<script>
import { isNil, remove, reject } from 'ramda';
import { computed } from 'vue';
import { DateTime } from 'luxon';

import { Button, PeriodNavigator } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { useBillingsWithNavigation } from '@/modules/billing';

import { createDifferenceRow, createCreditRow } from './utils';
import { useOrders, useBillingPatch } from './compositions';
import { Match, OrderDifferencesTable, CreditsTable } from './components';

export default {
  components: { Button, Match, OrderDifferencesTable, CreditsTable, PeriodNavigator, DocumentModal, CloseIcon },
  props: {
    supplier: { type: Object, required: true },
    businessId: { type: String, required: true },
    defaultMonth: {
      type: [String, Number],
      default: '',
    },
    selectedBillingId: { type: String, default: null },
  },
  setup(props) {
    const businessId = computed(() => props.businessId);
    const supplierId = computed(() => props.supplier.id);

    const month = computed(() => {
      if (typeof props.defaultMonth === 'number') {
        return DateTime.fromMillis(props.defaultMonth).toFormat('yyyy-MM');
      } else if (typeof props.defaultMonth === 'string' && props.defaultMonth.match(/^\d{4}-\d{2}$/)) {
        return props.defaultMonth;
      }

      return DateTime.now().toFormat('yyyy-MM');
    });

    const {
      orders,
      loading: orderLoading,
      range: ordersRange,
      goForward: goForwardOrders,
      goBackward: goBackwardOrders,
      refetch: orderRefetch,
    } = useOrders(supplierId, businessId, month.value);
    const {
      billings,
      loading: billingsLoading,
      range: billingsRange,
      goForward: goForwardBillings,
      goBackward: goBackwardBillings,
    } = useBillingsWithNavigation(businessId, supplierId, month.value);

    const {
      patchBilling,
      loading: patchInProgress,
      onDone: billingPatchOnDone,
    } = useBillingPatch(supplierId, ordersRange);

    billingPatchOnDone(() => orderRefetch());

    return {
      orders,
      orderLoading,
      ordersRange,
      goForwardOrders,
      goBackwardOrders,
      billings,
      billingsLoading,
      billingsRange,
      goForwardBillings,
      goBackwardBillings,
      patchBilling,
      patchInProgress,
    };
  },
  data() {
    return {
      differenceItem: {},
      creditItem: {},
      previewDocumentId: null,
      firstLoad: true,
    };
  },
  computed: {
    ordersDifferences() {
      return this.orders
        .filter((order) => order.diffs?.length)
        .map(createDifferenceRow)
        .sort((a, b) => a.date - b.date);
    },
    credits() {
      return this.billings
        .filter(({ netAmount }) => netAmount < 0)
        .map(createCreditRow)
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
  },
  watch: {
    ordersRange: function () {
      this.differenceItem = {};
    },
    billingsRange: function () {
      this.creditItem = {};
    },
    billingsLoading: function () {
      const creditItem = this.credits.find((credit) => credit.id === this.selectedBillingId);
      if (this.firstLoad && creditItem) {
        this.creditItem = creditItem;
        this.firstLoad = false;
      }
    },
  },
  methods: {
    async handleLink(amount) {
      if (isNil(amount)) return;
      const loading = this.$loading();
      try {
        const adjustedAmount = Math.round(amount * 100) * -1;
        const billing = this.billings.find((billing) => billing.id === this.creditItem.id);
        const orderLinks = billing.orderLinks.map(({ amount, externalRef, order }) =>
          reject(isNil, {
            amount,
            externalRef,
            orderId: order && order.id,
          })
        );
        const index = orderLinks.findIndex(({ orderId }) => orderId === this.differenceItem.id);
        if (index === -1) {
          orderLinks.push({
            orderId: this.differenceItem.id,
            amount: adjustedAmount,
          });
        } else {
          orderLinks[index].amount = adjustedAmount;
        }

        await this.patchBilling({
          id: this.creditItem.id,
          data: { orderLinks },
        });

        this.creditItem = {};
        this.differenceItem = {};
        this.$message.success(this.$i18n.t('commons.messages.action.success'));
      } catch (e) {
        console.error(e); //TODO: add to logger when UI will have one
        this.$message.error(this.$i18n.t('commons.messages.action.error'));
      } finally {
        loading.close();
      }
    },
    async handleUnlink({ id, orderId, linkIndex }) {
      const loading = this.$loading();
      try {
        const order = this.orders.find(({ id }) => id === orderId);
        const billing =
          this.billings.find((billing) => billing.id === id) ?? order.billings.find((billing) => billing.id === id);
        const updatedLinks = remove(linkIndex, 1, billing.orderLinks);
        const orderLinks = updatedLinks.map(({ amount, externalRef, order }) =>
          reject(isNil, {
            amount,
            externalRef,
            orderId: order && order.id,
          })
        );

        await this.patchBilling({
          id,
          data: { orderLinks },
        });

        this.$message.success(this.$i18n.t('commons.messages.action.success'));
      } catch (e) {
        console.error(e); //TODO: add to logger when UI will have one
        this.$message.error(this.$i18n.t('commons.messages.action.error'));
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.header {
  .title {
    font-weight: 700;
    font-size: 1.7em;
  }
}

.modal-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tables {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    margin-bottom: 20px;
    background: $white;
    padding: 0px 30px 20px;
    gap: 15px;

    .divider {
      height: 100%;
      width: 1px;
      background: $outline;
    }

    .table-wrapper {
      display: grid;
      grid-template-rows: 24px 1fr;
      gap: 10px;

      .table-header {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .table-title {
        color: $typography-primary;
        font-size: 1.15em;
      }
    }
  }
}

::v-deep .credit-match-modal {
  $modal-width: 95vw;
  color: $typography-primary;
  height: 93vh;
  width: $modal-width;
  border-radius: 6px;
  margin-bottom: 0;
  background: $light-gray;
  display: grid;
  grid-template-rows: 100px 1fr 65px;

  .el-dialog__header {
    background: $white;
    border-radius: 6px 6px 0px 0px;
  }

  .el-dialog__body {
    padding: 0px;
    // width: calc(#{$modal-width} - 1px);
    width: $modal-width;
  }
}
</style>

<template>
  <div class="matching-table-wrapper">
    <el-table height="100%" class="matching-table" :data="credits" @row-click="$emit('row-click', $event)">
      <el-table-column v-slot="{ $index }" width="30">
        <div class="radio-btn-wrapper">
          <div class="radio-btn" :class="{ selected: $index === selectedIndex }" />
        </div>
      </el-table-column>
      <el-table-column v-slot="{ row: { date } }" :label="$t('creditMatchModal.table.common.label.date')">
        {{ formatDate(date) }}
      </el-table-column>
      <el-table-column v-slot="{ row: { document } }" :label="$t('creditMatchModal.table.credits.label.document')">
        <a href="" @click.prevent.stop="$emit('document-click', document.id)">
          {{ $t(`document.exports.schema.type.shortName.${document.type}`) }} {{ document.documentNumber }}
        </a>
      </el-table-column>
      <el-table-column
        v-slot="{ row: { totalAmount } }"
        :label="$t('creditMatchModal.table.credits.label.totalCredit')"
      >
        {{ formatMoney(totalAmount) }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: { balance, isFullyMatched, totalAmount, matchedLinks } }"
        :label="$t('creditMatchModal.table.common.label.matchBalance')"
      >
        <template v-if="matchedLinks.length === 0">{{ $t('creditMatchModal.table.common.notMatched') }}</template>
        <template v-else>
          <el-popover placement="left" width="396">
            <MatchedList
              :total="totalAmount"
              :balance="balance"
              :matched-links="matchedLinks"
              @unlink-matched="$emit('unlink-matched', $event)"
            />
            <a slot="reference" href="" :class="{ 'fully-matched': isFullyMatched }" @click.prevent.stop>
              {{ isFullyMatched ? $t('creditMatchModal.table.common.matched') : formatMoney(balance) }}
            </a>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-summary">
      <div>
        {{ $t('creditMatchModal.table.credits.label.total') }}
      </div>
      <div class="total-summary">
        <span>
          {{ formatMoney(summaries.totalCredit) }}
        </span>
        <span>
          {{ formatMoney(summaries.totalBalance) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import MatchedList from './MatchedList';

export default {
  components: { MatchedList },
  props: {
    credits: { type: Array, default: () => [] },
    selectedIndex: { type: Number, default: -1 },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency();

    return { formatCentsToCurrency };
  },
  computed: {
    summaries() {
      const { totalCredit, totalBalance } = this.credits.reduce(
        (acc, { totalAmount, balance }) => {
          acc.totalCredit += Math.abs(totalAmount);
          acc.totalBalance += Math.abs(balance);
          return acc;
        },
        { totalCredit: 0, totalBalance: 0 }
      );

      return {
        totalCredit,
        totalBalance,
      };
    },
  },
  methods: {
    formatMoney(value) {
      return this.formatCentsToCurrency(value);
    },
    formatDate(isoDate) {
      return isoDate ? new Date(isoDate).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import './style';
</style>
